import React, {useState} from "react";
import axios from "axios";
import parse from 'html-react-parser';
import {graphql, useStaticQuery, navigate} from "gatsby";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";

import {
    CheckboxForm,
    CheckboxWrapper,
    FormBenefits,
    FormBenefitsWrapper,
    Input,
    SelectForm
} from "../styled/forms/FormComponents";
import Col from "../styled/grid/Col";
import {Body, Header3} from "../styled/typography/Typography";
import {Button} from "../styled/button/ButtonComponents";

import CountriesHU from "../../../static/countries/hu/countries.json";
import CountriesEN from "../../../static/countries/en/countries.json";

const Form = ({form, lang, isContact, isTraining, action}) => {

    const data = useStaticQuery(graphql`
    {
      allStrapiTrainingSubpages {
        trainings: nodes {
          lang
          title
        }
      }
    }
  `)

    const {allStrapiTrainingSubpages: {trainings}} = data

    const [company, setCompany] = useState("");
    const [company_address, setCompany_address] = useState("");
    const [firstname, setFirstname] = useState("");
    const [lastname, setLastname] = useState("");
    const [job_title, setJob_title] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [country, setCountry] = useState("");
    const [contact_person_name, setContact_person_name] = useState("");
    const [contact_person_email, setContact_person_email] = useState("");
    const [contact_person_phone, setContact_person_phone] = useState("");
    const [preferred_contact, setPreferred_contact] = useState("");
    const [product, setProduct] = useState("");
    const [subProduct, setSubProduct] = useState("");
    const [message, setMessage] = useState("");

    const SubmitForm = () => {

        if (action === "callback") {
            axios.post('/contactforms_new/v1/contact/universal', {
                //Required
                firstname: firstname,
                lastname: lastname,
                email: email,
                subject: product + " - " + subProduct,
                message: message,
                endpoint: action,
                //Optional
                phone: phone,
                preferred_contact: preferred_contact,
                country: country
            }).then(function (response) {
                console.log(response)
                if (response.status === 200 || response.status === 201) {
                    navigate(`/${lang}/forms/message-sent`)
                }
            }).catch(function (error) {
                console.log(error)
                alert("Váratlan hiba történt a form küldése közben. Kérjük írjon egy emailt az info@balasys.hu-ra.")
            });
        } else if (isContact) {
            axios.post('/contactforms_new/v1/contact/universal', {
                //Required
                firstname: firstname,
                lastname: lastname,
                email: email,
                subject: product,
                message: message,
                endpoint: "mail",
                //Optional
                phone: phone,
                preferred_contact: preferred_contact,
                country: country
            }).then(function (response) {
                console.log(response)
                if (response.status === 200 || response.status === 201) {
                    navigate(`/${lang}/forms/message-sent`)
                }
            }).catch(function (error) {
                console.log(error)
                alert("Váratlan hiba történt a form küldése közben. Kérjük írjon egy emailt az info@balasys.hu-ra.")
            });
        } else if (isTraining) {
            axios.post('/contactforms_new/v1/contact/universal', {
                //Required
                firstname: firstname,
                lastname: lastname,
                email: email,
                subject: product,
                message: message,
                endpoint: "callback",
                //Optional
                company: company,
                company_address: company_address,
                job_title: job_title,
                phone: phone,
                country: country,
                contact_person_name: contact_person_name,
                contact_person_phone: contact_person_phone,
                contact_person_email: contact_person_email,
                preferred_contact: preferred_contact
            }).then(function (response) {
                console.log(response)
                if (response.status === 200 || response.status === 201) {
                    navigate(`/${lang}/forms/message-sent`)
                }
            }).catch(function (error) {
                console.log(error)
                alert("Váratlan hiba történt a form küldése közben. Kérjük írjon egy emailt az info@balasys.hu-ra.")
            });
        }
    }

    return (
        <FormBenefitsWrapper>
            <FormBenefits onSubmit={(event) => {
                event.preventDefault();
                SubmitForm();
            }} id={"form-" + isContact ? "contact" : (isTraining ? "training" : "action")}>
                {form.Form.company &&
                <Col>
                    <Header3 color="primary-brand">
                        {lang === "hu" ? "Céginformációk" : "Company"}
                    </Header3>
                </Col>
                }
                {isContact &&
                <Col>
                    <Header3 color="primary-brand">
                        {lang === "hu" ? "Küldjön üzenetet!" : "Contact Us"}
                    </Header3>
                </Col>
                }
                {form.Form.company &&
                <Input
                    id="company"
                    name="company"
                    span={4}
                    type="text"
                    variant="outlined"
                    size="small"
                    label={lang === "hu" ? "Cégnév:" : "Company:"}
                    required
                    value={company}
                    onChange={(e) => setCompany(e.target.value)}
                />
                }
                {form.Form.company_address &&
                <Input
                    id="company_address"
                    name="company_address"
                    span={8}
                    variant="outlined"
                    size="small"
                    type="text"
                    label={lang === "hu" ? "Cég címe:" : "Company address:"}
                    required
                    value={company_address}
                    onChange={(e) => setCompany_address(e.target.value)}
                />
                }
                {form.Form.contact_person_name &&
                <Col>
                    <Header3 color="primary-brand">
                        {lang === "hu" ? "Résztvevői információ" : "Participant"}
                    </Header3>
                </Col>
                }
                {form.Form.first_name && !form.Form.job_title &&
                <Input
                    id="firstName"
                    name="firstname"
                    span={6}
                    variant="outlined"
                    size="small"
                    type="text"
                    label={lang === "hu" ? "Vezetéknév:" : "First name:"}
                    value={firstname}
                    onChange={(e) => setFirstname(e.target.value)}
                    required
                />
                }
                {form.Form.last_name && !form.Form.job_title &&
                <Input
                    id="lastName"
                    name="lastname"
                    span={6}
                    variant="outlined"
                    size="small"
                    type="text"
                    label={lang === "hu" ? "Keresztnév:" : "Last name:"}
                    value={lastname}
                    onChange={(e) => setLastname(e.target.value)}
                    required
                />
                }
                {form.Form.first_name && form.Form.job_title &&
                <Input
                    span={4}
                    variant="outlined"
                    size="small"
                    type="text"
                    id="firstName"
                    name="firstname"
                    label={lang === "hu" ? "Vezetéknév:" : "First name:"}
                    value={firstname}
                    onChange={(e) => setFirstname(e.target.value)}
                    required
                />
                }
                {form.Form.last_name && form.Form.job_title &&
                <Input
                    span={4}
                    variant="outlined"
                    size="small"
                    type="text"
                    id="lastName"
                    name="lastname"
                    label={lang === "hu" ? "Keresztnév:" : "Last name:"}
                    value={lastname}
                    onChange={(e) => setLastname(e.target.value)}
                    required
                />
                }
                {form.Form.job_title &&
                <Input
                    span={4}
                    variant="outlined"
                    size="small"
                    type="text"
                    id="job_title"
                    name="job_title"
                    label={lang === "hu" ? "Pozíció:" : "Job title:"}
                    value={job_title}
                    onChange={(e) => setJob_title(e.target.value)}
                    required
                />
                }
                {form.Form.phone &&
                <Input
                    span={4}
                    variant="outlined"
                    size="small"
                    type="text"
                    id="phone"
                    name="phone"
                    pattern="^\+?\d[\d\- ]+$"
                    label={lang === "hu" ? "Telefonszám:" : "Phone number:"}
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    required
                />
                }
                {form.Form.email &&
                <Input
                    span={4}
                    variant="outlined"
                    size="small"
                    type="email"
                    id="email"
                    name="email"
                    pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                    label="E-mail:"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                />
                }
                {form.Form.country &&
                <SelectForm variant="outlined" size="small" span={4} required>
                    <InputLabel
                        id="preffered_contact-label">{lang === "hu" ? "Ország:" : "Country:"}</InputLabel>
                    <Select
                        native
                        id="country"
                        name="country"
                        value={country}
                        onChange={(e) => setCountry(e.target.value)}
                        label={lang === "hu" ? "Ország:" : "Country:"}
                    >
                        <option aria-label="None" value=""/>
                        {lang === "hu" && CountriesHU.map((item => (
                            <option value={item.alpha2} key={item.alpha2}>{item.name}</option>
                        )))}
                        {lang === "en" && CountriesEN.map((item => (
                            <option value={item.alpha2} key={item.alpha2}>{item.name}</option>
                        )))}
                    </Select>
                </SelectForm>
                }
                {form.Form.contact_person_name &&
                <Col>
                    <Header3 color="primary-brand">
                        {lang === "hu" ? "Kapcsolattartó" : "Contact person"}
                    </Header3>
                </Col>
                }
                {form.Form.contact_person_name &&
                <Input
                    span={4}
                    variant="outlined"
                    size="small"
                    type="text"
                    id="contact_person_name"
                    name="contact_person_name"
                    label={lang === "hu" ? "Név:" : "Name:"}
                    value={contact_person_name}
                    onChange={(e) => setContact_person_name(e.target.value)}
                    required
                />
                }
                {form.Form.contact_person_email &&
                <Input
                    span={4}
                    variant="outlined"
                    size="small"
                    type="text"
                    id="contact_person_email"
                    name="contact_person_email"
                    label={lang === "hu" ? "E-mail cím:" : "Email:"}
                    value={contact_person_email}
                    onChange={(e) => setContact_person_email(e.target.value)}
                    required
                />
                }
                {form.Form.contact_person_phone &&
                <Input
                    span={4}
                    variant="outlined"
                    size="small"
                    type="text"
                    id="contact_person_phone"
                    name="contact_person_phone"
                    label={lang === "hu" ? "Telefonszám:" : "Phone:"}
                    value={contact_person_phone}
                    onChange={(e) => setContact_person_phone(e.target.value)}
                    required
                />
                }
                {form.Form.preffered_dropdown &&
                <SelectForm variant="outlined" size="small" required>
                    <InputLabel
                        id="product-label">{lang === "hu" ? "Hogyan kereshetjük önt?" : "Preferred communication"}</InputLabel>
                    <Select
                        native
                        name="preferred_contact"
                        id="preferred_contact"
                        value={preferred_contact}
                        onChange={(e) => setPreferred_contact(e.target.value)}
                        label={lang === "hu" ? "Hogyan kereshetjük önt?" : "Preferred communication"}
                    >
                        <option aria-label="None" value=""/>
                        <option value="phone">{lang === "hu" ? "Telefonon" : "Phone call"}</option>
                        <option value="email">{lang === "hu" ? "E-mailben" : "E-mail"}</option>
                    </Select>
                </SelectForm>
                }
                {form.Form.help_dropdown && !isContact && !isTraining &&
                <SelectForm variant="outlined" size="small" required span={6}>
                    <InputLabel
                        id="product-label">{lang === "hu" ? "Mivel kapcsolatban érdeklődik?" : "What can we help you with?"}</InputLabel>
                    <Select
                        native
                        name="product"
                        id="product"
                        value={product}
                        onChange={(e) => setProduct(e.target.value)}
                        label={lang === "hu" ? "Mivel kapcsolatban érdeklődik?" : "What can we help you with?"}
                    >
                        <option aria-label="None" value=""/>
                        <option
                            value={lang === "hu" ? "Hálózatbiztonság" : "Network security"}>{lang === "hu" ? "Hálózatbiztonság" : "Network security"}</option>
                        <option
                            value={lang === "hu" ? "API-biztonság" : "API security"}>{lang === "hu" ? "API-biztonság" : "API security"}</option>
                        <option
                            value={lang === "hu" ? "Jogosultságkezelés" : "Identity governance"}>{lang === "hu" ? "Jogosultságkezelés" : "Identity governance"}</option>
                        <option
                            value={lang === "hu" ? "AD-biztonság és -kezelés" : "AD security and management"}>{lang === "hu" ? "AD-biztonság és -kezelés" : "AD security and management"}</option>
                        <option
                            value={lang === "hu" ? "Kiemeltfelhasználó-kezelés" : "Privileged access management"}>{lang === "hu" ? "Kiemeltfelhasználó-kezelés" : "Privileged access management"}</option>
                        <option
                            value={lang === "hu" ? "Naplókezelés" : "Log management"}>{lang === "hu" ? "Naplókezelés" : "Log management"}</option>
                        <option
                            value={lang === "hu" ? "IT-menedzsment" : "IT management"}>{lang === "hu" ? "IT-menedzsment" : "IT management"}</option>
                        <option
                            value={lang === "hu" ? "Ipari biztonság" : "ICS security"}>{lang === "hu" ? "Ipari biztonság" : "ICS security"}</option>
                        <option
                            value={lang === "hu" ? "IoT-biztonság" : "IoT security"}>{lang === "hu" ? "IoT-biztonság" : "IoT security"}</option>
                    </Select>
                </SelectForm>
                }
                {form.Form.help_dropdown && !isContact && !isTraining &&
                <SelectForm variant="outlined" size="small" required span={6} disabled={product === ""}>
                    <InputLabel
                        id="product-label">{lang === "hu" ? "Melyik termékkel kapcsolatban érdeklődik?" : "Which product are you interested in?"}</InputLabel>
                    <Select
                        native
                        name="subProduct"
                        id="subProduct"
                        value={subProduct}
                        onChange={(e) => setSubProduct(e.target.value)}
                        label={lang === "hu" ? "Melyik termékkel kapcsolatban érdeklődik?" : "Which product are you interested in?"}
                    >
                        <option aria-label="None" value=""/>
                        {(product === "Hálózatbiztonság" || product === "Network security") &&
                        (lang === "hu" ?
                                <>
                                    <option value="Zorp Gateway">Zorp Gateway</option>
                                    <option value="Zorp Malware Detection">Zorp Malware Detection</option>
                                </> : <option value="Proxedo Network Security">Proxedo Network Security</option>
                        )
                        }
                        {(product === "API-biztonság" || product === "API security") &&
                        <option value="Proxedo API Security">Proxedo API Security</option>
                        }
                        {(product === "Jogosultságkezelés" || product === "Identity governance") &&
                        <>
                            <option value="Identity Manager">Identity Manager</option>
                            <option value="Starling Connect">Starling Connect</option>
                            <option value="Starling Two-Factor Authentication">Starling Two-Factor Authentication
                            </option>
                        </>
                        }
                        {(product === "AD-biztonság és -kezelés" || product === "AD security and management") &&
                        <>
                            <option value="Active Roles">Active Roles</option>
                            <option value="Starling Connect">Starling Connect</option>
                            <option value="Starling Two-Factor Authentication">Starling Two-Factor Authentication
                            </option>
                        </>
                        }
                        {(product === "Kiemeltfelhasználó-kezelés" || product === "Privileged access management") &&
                        <>
                            <option value="Safeguard for Privileged Sessions">Safeguard for Privileged Sessions</option>
                            <option value="Safeguard for Privileged Passwords">Safeguard for Privileged Passwords
                            </option>
                            <option value="Safeguard for Privileged Analytics">Safeguard for Privileged Analytics
                            </option>
                            <option
                                value={lang === "hu" ? "Safeguard termékcsalád" : "Safeguard product family"}>{lang === "hu" ? "Safeguard termékcsalád" : "Safeguard product family"}</option>
                            <option value="Safeguard Authentication Services">Safeguard Authentication Services</option>
                            <option value="Starling Two-Factor Authentication">Starling Two-Factor Authentication
                            </option>
                        </>
                        }
                        {(product === "Naplókezelés" || product === "Log management") &&
                        <>
                            <option value="syslog-ng Store Box">Zorp Gateway</option>
                            <option value="syslog-ng Premium Edition">Zorp Malware Detection</option>
                        </>
                        }
                        {(product === "IT-menedzsment" || product === "IT management") &&
                        <>
                            <option value="Change Auditor">Change Auditor</option>
                            <option value="Enterprise Reporter">Enterprise Reporter</option>
                            <option value="Active Administrator">Active Administrator</option>
                            <option value="GPOADmin">GPOADmin</option>
                            <option value="Recovery Manager">Recovery Manager</option>
                            <option value="Metalogix ControlPoint">Metalogix ControlPoint</option>
                            <option value="Metalogix Backup for Sharepoint">Metalogix Backup for Sharepoint</option>
                            <option value="KACE">KACE</option>
                            <option value="Toad">Toad</option>
                        </>
                        }
                        {product === "Ipari biztonság" &&
                        <option value="Ipari biztonsági megoldások">Ipari biztonsági megoldások</option>
                        }
                        {product === "IoT-biztonság" &&
                        <option value="IoT-biztonsági megoldások">IoT-biztonsági megoldások</option>
                        }
                        {product === "ICS security" &&
                        <option value="ICS-security solutions">ICS-security solutions</option>
                        }
                        {product === "IoT security" &&
                        <option value="IoT-security solutions">IoT-security solutions</option>
                        }
                    </Select>
                </SelectForm>}
                {form.Form.help_dropdown && isContact && !isTraining &&
                <SelectForm variant="outlined" size="small" required>
                    <InputLabel
                        id="product-label">{lang === "hu" ? "Mivel kapcsolatban érdeklődik?" : "What can we help you with?"}</InputLabel>
                    <Select
                        native
                        name="product"
                        id="product"
                        value={product}
                        onChange={(e) => setProduct(e.target.value)}
                        label={lang === "hu" ? "Mivel kapcsolatban érdeklődik?" : "What can we help you with?"}
                    >
                        <option aria-label="None" value=""/>
                        <option value="products">{lang === "hu" ? "Termékeink" : "Products"}</option>
                        <option value="services">{lang === "hu" ? "Szolgáltatásaink" : "Services"}</option>
                        <option value="information">{lang === "hu" ? "Általános információ" : "Information"}</option>
                        <option value="job">{lang === "hu" ? "Állásaink" : "Job"}</option>
                    </Select>
                </SelectForm>
                }
                {form.Form.help_dropdown && !isContact && isTraining &&
                <>
                    <Col>
                        <Header3 color="primary-brand">
                            {lang === "hu" ? "Tréning" : "Training"}
                        </Header3>
                    </Col>
                    <SelectForm variant="outlined" size="small" required>
                        <InputLabel
                            id="product-label">{lang === "hu" ? "Mivel kapcsolatban érdeklődik?" : "What can we help you with?"}</InputLabel>
                        <Select
                            native
                            name="product"
                            id="product"
                            value={product}
                            onChange={(e) => setProduct(e.target.value)}
                            label={lang === "hu" ? "Mivel kapcsolatban érdeklődik?" : "What can we help you with?"}
                        >
                            <option aria-label="None" value=""/>
                            {trainings.filter(f => f.lang === lang).map((item, index) => (
                                <option key={index} value={item.title}>{item.title}</option>
                            ))}
                        </Select>
                    </SelectForm>
                </>
                }
                {form.Form.message &&
                <Input
                    name="message"
                    id="message"
                    variant="outlined"
                    size="small"
                    type="text"
                    multiline
                    minRows={4}
                    label={lang === "hu" ? "Üzenet:" : "Message:"}
                    required={isContact}
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                />
                }
                <Col grid span={9} verticalcenter>
                    <CheckboxWrapper>
                        <CheckboxForm
                            color="primary"
                            id="accept_eula"
                            name="accept_eula"
                            inputProps={{'aria-label': 'secondary checkbox'}}
                            required
                        />
                        <Body color="body-text" small>
                            {lang === "hu" ? parse("* Elolvastam és elfogadom az <a href=\"/hu/privacy-policy\" target=\"_blank\" rel=\"noreferrer\" >adatvédelmi szabályzatot</a>!") : parse("* I have read and accept the <a href=\"/en/privacy-policy\" target=\"_blank\" rel=\"noreferrer\" >terms & conditions</a>.")}
                        </Body>
                    </CheckboxWrapper>
                </Col>
                <Col span={3}>
                    <Button
                        id="button"
                        type="submit"
                        filled="true"
                    >
                        {lang === "hu" ? "Küldés" : "Submit"}
                    </Button>
                </Col>
            </FormBenefits>
        </FormBenefitsWrapper>
    )
}

export default Form;